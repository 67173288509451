import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import ISO6391 from 'iso-639-1';
import React, { useEffect, useState } from "react";
import useOnclickOutside from 'react-cool-onclickoutside';
// @ts-ignore
import Flag from 'react-country-flag';
import styled, {css} from 'styled-components';

type Props = {
  bottom?: boolean;
  className?: string;
};

const CountryFlag = styled(Flag)`
  max-width: none;
  margin-bottom: 0;
`;

const Container = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 10px;
  position: relative;
  z-index: 10;
`;

const Selected = styled.div`
  cursor: pointer;
  transition: 250ms color;
`;

const CountryList = styled.ul<{visible: boolean; bottom: boolean}>`
  list-style: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
  color: initial;
  border-radius: 3px;
  width: 175px;
  left: -15px;
  margin: 0;
  padding: 5px 0;
  top: 100%;
  pointer-events: none;
  transform: rotateX(-15deg);
  transform-origin: 50% -50px;
  opacity: 0;
  will-change: transform, opacity;
  transition-property: transform, opacity;
  transition-duration: 0.25s;
  @media (max-width: 768px) {
    left: -58px;
    :before {
      left: 88px !important;
    }
  }
  :before {
    content: '';
    display: block;
    position: absolute;
    top: -6px;
    left: 25px;
    margin: 0 0 0 -6px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    border-radius: 4px 0 0 0;
    background: #fff;
    box-shadow: -3px -3px 5px rgba(82, 95, 127, 0.04);
    will-change: transform;
    transition-property: -webkit-transform;
  }

  ${(props) =>
    props.bottom &&
    css`
      top: auto;
      bottom: 100%;
      transform: rotateX(-15deg);
      transform-origin: 50% calc(100% + 50px);
      :before {
        bottom: -6px;
        top: auto;
      }
    `}

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      pointer-events: auto;
      transform: none;
    `}
`;

const CountryItem = styled.li`
  position: relative;
  margin-bottom: 0;
  z-index: 1;
  && a {
    padding: 5px 15px;
    cursor: pointer;
    color: inherit;
    display: block;
    width: 100%;
    :hover {
      color: inherit;
      background: rgba(185, 199, 255, 0.2);
    }
  }
`;

const CountryName = styled.span`
  margin-left: 8px;
`;

const flagMap: Record<string, string> = {
  en: 'gb',
  ca: 'es-ca'
};

const CountryLabel: React.FC<{code: string}> = ({code}) => (
  <>
    <CountryFlag
      countryCode={flagMap[code] ? flagMap[code] : code}
      svg
      alt={code}
      cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.5.0/flags/4x3/"
      cdnSuffix="svg"
    />
    <CountryName>{ISO6391.getNativeName(code)}</CountryName>
  </>
);

export const LangSelector: React.FC<Props> = ({bottom = false, className}) => {
  const [isOpen, setOpen] = useState(false);
  const {language, languages, originalPath} = useI18next();
  const [locationSearch, setLocationSearch] = useState('');

  const ref = useOnclickOutside(() => {
    setOpen(false);
  });

  const handleClick = async (code: string) => {
    setOpen(false);
  };

  useEffect(() => {
    setLocationSearch(window.location.search);
  }, []);

  return (
    <Container ref={ref as any} className={className}>
      <Selected onClick={() => setOpen((value) => !value)}>
        <CountryLabel code={language} />
      </Selected>
      <CountryList visible={isOpen} bottom={bottom}>
        {languages.map((code) => {
          return (
            <CountryItem key={code} onClick={() => handleClick(code)}>
              <Link to={originalPath + locationSearch} language={code}>
                <CountryLabel code={code} />
              </Link>
            </CountryItem>
          );
        })}
      </CountryList>
    </Container>
  );
};
