import {useField} from 'formik';
import React, {ReactElement} from 'react';
import styled, {css} from 'styled-components';
import {Field as FormikField} from 'formik';

export const Input = styled(FormikField)`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  padding: 0 12px;
  line-height: 41px;
  outline: none;
  color: hsla(0, 0%, 10%, 0.9);
  border: 0;
  border-radius: ${(props) => props.theme.borderRadius};
  box-shadow: 0 0 0 1px #e0e0e0, 0 2px 4px 0 rgba(0, 0, 0, 0.07), 0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.08s ease-in, color 0.08s ease-in;
  background: #fff;
  appearance: none;

  &:focus,
  &:active {
    box-shadow: 0 0 0 1px rgba(50, 151, 211, 0.3), 0 1px 1px 0 rgba(0, 0, 0, 0.07),
      0 0 0 4px rgba(50, 151, 211, 0.3);
  }

  &.has-error {
    color: #dc2727;
    box-shadow: 0 0 0 1px rgb(245 34 45 / 57%), 0 2px 4px 0 rgb(245 34 45 / 7%),
      0 1px 1.5px 0 rgb(245 34 45 / 5%);
  }
`;

export const Error = styled.div`
  color: #f5222d;
  font-size: 14px;
  margin-top: 5px;
  text-align: left;
`;

const StyledField = styled.div<{isInvalid?: boolean; slideError?: boolean}>`
  margin-bottom: 15px;
  position: relative;
  input,
  select {
    height: 41px;
  }
  ${(props) =>
    props.isInvalid &&
    css`
      padding-bottom: ${props.slideError ? '15px' : 0};
      label {
        color: #dc2727;
      }
      input {
        color: #dc2727;
        box-shadow: 0 0 0 1px #ef9896, 0 2px 4px 0 rgba(0, 0, 0, 0.07),
          0 1px 1.5px 0 rgba(0, 0, 0, 0.05);
      }
    `}
`;

export const Label = styled.label`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

type FieldProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: ReactElement | string;
  name: string;
  slideError?: boolean;
  as?: string;
  [key: string]: any;
};

export const Field: React.FC<FieldProps> = ({label, name, as, ...props}) => {
  const [field, meta] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <StyledField isInvalid={isInvalid} slideError={false}>
      {label && <Label>{label}</Label>}
      <Input as={as as any} type="text" {...field} {...props} />
      {isInvalid && <Error>{meta.error}</Error>}
    </StyledField>
  );
};

const SelectArrow = styled.svg`
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -4px;
  pointer-events: none;
  width: 12px;
  height: 12px;
  z-index: 3;
`;

const SelectWrapper = styled.div`
  position: relative;
`;

type SelectFieldProps = React.InputHTMLAttributes<HTMLSelectElement> & {
  label?: ReactElement | string;
  name: string;
  slideError?: boolean;
  as?: string;
  [key: string]: any;
};

export const SelectField: React.FC<SelectFieldProps> = ({name, label, ...props}) => {
  const [field, meta] = useField(name);
  const isInvalid = Boolean(meta.error && meta.touched);
  return (
    <StyledField>
      {label && <Label>{label}</Label>}
      <SelectWrapper>
        <Input {...field} {...(props as any)} as="select" />
        <SelectArrow focusable="false" width="12" height="12">
          <path
            d="M10.193 3.97a.75.75 0 0 1 1.062 1.062L6.53 9.756a.75.75 0 0 1-1.06 0L.745 5.032A.75.75 0 0 1 1.807 3.97L6 8.163l4.193-4.193z"
            fillRule="evenodd"
          />
        </SelectArrow>
      </SelectWrapper>
      {isInvalid && <Error>{meta.error}</Error>}
    </StyledField>
  );
};
