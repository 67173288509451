import styled from 'styled-components';
import {StyledDiv} from './StyledSx';
import {containerSize} from '../../types/responsive';

export const Content = styled(StyledDiv)<{fullWidth?: boolean}>`
  max-width: ${(props) => (props.fullWidth ? 'none' : containerSize)};
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  position: relative;
  z-index: 11;
`;
