import React from "react";
import styled, { css } from "styled-components";
import { ScreenSizes, Sx } from "../../types/responsive";
import { StyledDiv, StyledSection } from "./StyledSx";

type Props = {
  textAlign?: 'center' | 'left' | 'right';
  centered?: boolean;
  reverseOnMobile?: boolean;
  column?: boolean;
  columnMd?: boolean;
  columnLg?: boolean;
  sx?: Sx;
};

export const Section = styled(StyledSection)<Props>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 100px;
  padding-bottom: 100px;
  text-align: ${(props) => props.textAlign || 'left'};
  margin: 0 -30px;

  & > * {
    flex: 1;
    margin: 0 30px;

    @media (max-width: ${ScreenSizes.lg}) {
      flex: 3;
    }
    @media (max-width: ${ScreenSizes.md}) {
      margin: 0;
    }
  }

  a {
    text-decoration: underline;
  }

  ${(props) =>
    props.centered &&
    css`
      justify-content: center;
      align-items: center;
    `};
  ${(props) =>
    props.column &&
    css`
      flex-direction: column;
    `};

  @media (max-width: ${ScreenSizes.xl}) {
    padding-top: 10%;
    padding-bottom: 5%;
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
        props.columnLg &&
        css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.md}) {
    padding-top: 11%;
    padding-bottom: 10%;
    ${(props) =>
      props.columnMd &&
      css`
        width: 100%;
        align-items: center;
        flex-direction: ${props.reverseOnMobile ? 'column-reverse' : 'column'};
      `};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    padding-top: 50px;
    padding-bottom: 80px;
    flex-direction: ${(props) => (props.reverseOnMobile ? 'column-reverse' : 'column')};
  }
  @media (max-width: ${ScreenSizes.md}) {
    margin: 0;
  }
`;

type ImageContainerProps = {mobileWidth?: number, sx?: Sx};

export const ImageContainer = styled(StyledDiv)<ImageContainerProps>`
  display: flex;
  justify-content: center;

  img {
    margin-bottom: 0;
  }


  @media (max-width: ${ScreenSizes.md}) {
    padding-bottom: 40px;
    display: block;
    text-align: center;
    img {
      width: ${(props) => (props.mobileWidth ? `${props.mobileWidth}px` : '75%')};
      height: auto;
    }
  }
`;

export const ImageContainerFloating = styled(ImageContainer)`
  position: absolute;
`;

type SectionImageProps = React.ImgHTMLAttributes<HTMLImageElement> & ImageContainerProps;

export const SectionImage: React.FC<SectionImageProps> = ({mobileWidth, sx, ...props}) => (
  <ImageContainer mobileWidth={mobileWidth} sx={sx}>
    <img {...props} />
  </ImageContainer>
);

export const SectionImageFloating: React.FC<SectionImageProps> = ({
  mobileWidth,
  className,
  ...props
}) => (
  <ImageContainerFloating mobileWidth={mobileWidth} className={className}>
    <img {...props} />
  </ImageContainerFloating>
);

type SectionProps = {
  tagName?: string;
  underline?: boolean;
  light?: boolean;
  style?: any;
  sx?: Sx;
};

const HeaderContainer = styled(StyledDiv)<SectionProps>`
  display: inline-flex;
  flex-direction: column;

  h1 {
    font-size: 3rem;
    line-height: 3.6rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2.2rem;
      line-height: 3rem;
    }
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 2rem;
      line-height: 2.6rem;
    }
  }

  h2 {
    font-size: 2.4rem;
    line-height: 3rem;

    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 2rem;
      line-height: 2.6rem;
    }
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 1.8rem;
      line-height: 2.2rem;
    }
  }
  h3 {
    font-size: 2.2rem;
    @media (max-width: ${ScreenSizes.sm}) {
      font-size: 1.8rem;
      line-height: 2.3rem;
    }
    @media (max-width: ${ScreenSizes.xs}) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 350;
    ${(props) => (props.light ? `color: white;` : `color: inherit;`)}
    
    a { 
      text-decoration: none;
    }

  ${(props) =>
    props.underline &&
    css`
      & > * {
        margin-bottom: 1.2rem;
      }

      &:after {
        content: '';
        display: block;
        ${props.light
          ? `background: white;`
          : `background-image: linear-gradient(-135deg, #acb6e5 0%, #74ebd5 100%);`}
        margin-bottom: 1.2rem;
        height: 2px;
      }
    `}
`;

export const SectionHeader: React.FC<SectionProps> = ({
  tagName = 'h2',
  children,
  underline,
  sx,
  ...props
}) => (
  <HeaderContainer underline={underline} {...props} sx={sx}>
    {React.createElement(tagName, props, children)}
  </HeaderContainer>
);

export const SectionActions = styled(StyledDiv)<{align?: 'center' | 'left' | 'right'}>`
  margin-top: 2.5rem;

  & > * {
    margin: 5px 0;
  }

  ${(props) =>
    props.align === 'center' &&
    css`
      display: flex;
      align-items: center;
      flex-direction: column;
    `}

  ${(props) =>
    props.align === 'right' &&
    css`
      display: flex;
      align-items: flex-end;
      flex-direction: column;
    `}

  ${(props) =>
    props.align === 'left' &&
    css`
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    `}
`;
