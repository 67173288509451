import React from 'react';
import styled from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {colors, links} from 'global.config';
import {RiArrowRightLine} from 'react-icons/ri';
import {FeaturesSelector} from './FeaturesSelector';
import {UseCasesSelector} from './UseCasesSelector';
import {ScreenSizes} from '../../../types/responsive';
import {ResourcesSelector} from './ResourcesSelector';
import {Trans} from 'react-i18next';

const Menu = styled.ul`
  align-items: center;
  display: flex;
  flex-direction: row;
  list-style: none;
  padding: 0;
  margin: 0;

  @media (max-width: ${ScreenSizes.md}) {
    display: none;
  }
`;

const Arrow = styled(RiArrowRightLine)`
  position: relative;
  margin-left: 3px;
  left: 0;
  transition: 150ms left ease-in;
  top: 2px;
`;

const MenuItem = styled.li`
  display: flex;
  margin: 0 20px;
  a {
    display: block;
    position: relative;
    text-decoration: none;
    transition: 250ms color;
    color: inherit;
    :hover {
      color: ${colors.pink};
      ${Arrow} {
        left: 5px;
      }
    }
    &.active {
      color: ${colors.pink};
    }
  }
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
  @media (max-width: ${ScreenSizes.lg}) {
    margin: 0 12px;
  }
`;

export const DesktopMenu: React.FC = () => {
  return (
    <Menu>
      <FeaturesSelector />
      <UseCasesSelector />
      <ResourcesSelector />
      <MenuItem>
        <AnchorLink href={links.dashboard}>
          <Trans>Dashboard</Trans> <Arrow />
        </AnchorLink>
      </MenuItem>
    </Menu>
  );
};
