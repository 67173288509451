import {ButtonLink, ButtonProps} from 'components/Button';
import {links} from 'global.config';
import qs from 'query-string';
import React, {useEffect, useState} from 'react';

type Props = ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>;

const location = typeof window !== 'undefined' ? window.location : null;
const localStorage = typeof window !== 'undefined' ? window.localStorage : null;

const getRef = () => {
  if (!location) return undefined;
  let ref = qs.parse(location.search).ref as string | undefined;
  if (ref) {
    localStorage?.setItem('affiliate_ref', ref);
  } else {
    ref = localStorage?.getItem('affiliate_ref') || undefined;
  }
  return ref;
};

export const SignUpButton: React.FC<Props> = (props) => {
  const [state, setState] = useState(0);
  useEffect(() => {
    setState(1);
  }, []);
  const ref = getRef();
  const signUpUrl = ref ? `${links.signUp}&ref=${ref}` : links.signUp;
  return <ButtonLink key={state} {...props} href={signUpUrl} />;
};
