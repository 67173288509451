import React from 'react';
import {MenuItemIcon} from '../MenuItemIcon';
import {Link, Trans} from 'gatsby-plugin-react-i18next';
import {Item} from '../Styled';
import {AnchorLink} from 'components/AnchorLink';
import SoonIndicator from './SoonIndicator';
import { PrismicPage } from "components/links/PrismicPage";

export const EcommercePlatforms: React.FC<{onClick: VoidFunction}> = ({onClick}) => (
  <>
    <Item>
      <MenuItemIcon name="shopify" />
      <Link to="/shopify-payment-gateway/" activeClassName="active" onClick={onClick}>
        Shopify
      </Link>
    </Item>
    <Item>
      <MenuItemIcon name="woocommerce" />
      <Link to="/woocommerce-payment-gateway/" activeClassName="active" onClick={onClick}>
        WooCommerce
      </Link>
    </Item>
    <Item>
      <MenuItemIcon name="wix" />
      <Link to="/wix-payment-gateway/" activeClassName="active" onClick={onClick}>
        Wix
      </Link>
    </Item>
    <Item>
      <MenuItemIcon name="salesforce" />
      <AnchorLink href="https://docs.monei.com/docs/e-commerce/salesforce/" target="_blank">
        Salesforce
      </AnchorLink>
    </Item>
    <Item>
      <MenuItemIcon name="commercetools" />
      commercetools
      <SoonIndicator>
        <Trans>BETA</Trans>
      </SoonIndicator>
    </Item>
    <Item>
      <MenuItemIcon name="prestashop" />
      <Link to="/prestashop-payment-gateway/" activeClassName="active" onClick={onClick}>
        PrestaShop
      </Link>
    </Item>
    <Item>
      <MenuItemIcon name="magento" />
      <Link to="/magento-payment-gateway/">Adobe Commerce (Magento 2)</Link>
    </Item>
    <Item>
      <MenuItemIcon name="spreedly" />
      <AnchorLink target="_blank" href="https://docs.spreedly.com/payment-gateways/monei/">
        Spreedly
      </AnchorLink>
    </Item>
    <Item>
      <MenuItemIcon name="partner_integration" />
      <PrismicPage slug="payment-method-integrations-ecommerce-platforms">
        <Trans>See all e-commerce platform integrations</Trans>
      </PrismicPage>
    </Item>
  </>
);
