export const colors = {
  green: '#1ea672',
  pink: '#9d74b9',
  grey: '#b3b3b3'
};

export const links = {
  landing: process.env.GATSBY_STAGE === 'dev' ? 'https://mo-landing.microapps-staging.com' : 'https://monei.com',
  dashboard: 'https://dashboard.monei.com',
  signUp: 'https://dashboard.monei.com/?action=signUp',
  support: 'https://support.monei.com/hc/',
  pricing: 'https://support.monei.com/hc/en-us/articles/360017954318',
  feels_good: 'https://support.monei.com/hc/en-us/articles/360020966278',
  applePay: 'https://support.monei.com/hc/sections/360005144598-Apple-Pay',
  api: 'https://docs.monei.com/api/',
  docs: 'https://docs.monei.com',
  gettingStarted: 'https://docs.monei.com/docs/',
  contactSupport: 'mailto:support@monei.com',
  contactSales:
    'https://support.monei.com/hc/requests/new?tf_4450339294609=general_question&tf_4451372321809=no',
  facebook: 'https://www.facebook.com/GETMONEI',
  instagram: 'https://www.instagram.com/moneipayments',
  linkedin: 'https://www.linkedin.com/company/monei-digital-payments',
  github: 'https://github.com/MONEI/',
  eurm: 'https://wallet.eurm.org',
  supportUrl: 'https://support.monei.com/hc/',
  pinpointApi: process.env.GATSBY_STAGE === 'dev' ? 'https://pinpoint.microapps-staging.com/' : 'https://pinpoint.monei.com/',
};

export const configVars = {
  gtmId: 'GTM-NHS56QJ',
  measurementId: process.env.GATSBY_STAGE === 'dev' ? 'G-3P1JQLMQKG' : 'G-3P1JQLMQKG',
}

