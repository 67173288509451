import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import type {LayoutContextProps} from './types';

const LayoutContext = createContext<LayoutContextProps>({
  utmParams: '',
  setUtmParams: () => void {}
});

export const useLayout = (defaultValues?: Pick<LayoutContextProps, 'utmParams'>) => {
  const context = useContext(LayoutContext);

  useEffect(() => {
    if (!defaultValues) return;

    if (defaultValues.utmParams) {
      context.setUtmParams(defaultValues.utmParams);
    }
  }, [defaultValues, context]);

  return context;
};

export function LayoutProvider({children}: {children: ReactNode}) {
  const [utmParams, setUtmParams] = useState('');
  const changeUtmParams = useCallback((params: string) => setUtmParams(params), [setUtmParams]);

  return (
    <LayoutContext.Provider value={{utmParams, setUtmParams: changeUtmParams}}>
      {children}
    </LayoutContext.Provider>
  );
}
