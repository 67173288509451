import React, {useState} from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import styled, {css} from 'styled-components';
import {AnchorLink} from 'components/AnchorLink';
import {CloseIcon} from 'components/CloseIcon';
import {MenuIcon} from 'components/MenuIcon';
import {ButtonLink} from 'components/Button';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {links} from 'global.config';
import {MenuItemIcon} from './MenuItemIcon';
import {EWallets} from './menu-links/EWallets';
import {PeerToPeer} from './menu-links/PeerToPeer';
import {BankTransfer} from './menu-links/BankTransfer';
import {Item, SectionHeader} from './Styled';
import {EcommercePlatforms} from './menu-links/EcommercePlatforms';
import {Integrations} from './features-links/Integrations';
import {UseCases} from './use-cases/UseCases';
import {FreeTools} from './features-links/FreeTools';
import {ScreenSizes} from '../../../types/responsive';
import {InternalPageLink} from 'components/links/Pages';
import {Language, targetLanguage} from '../../../locales/types';

const MenuWrapper = styled.div<{visible: boolean}>`
  @media (max-width: ${ScreenSizes.md}) {
    box-shadow: ${(props) => props.theme.boxShadow};
    background: #ffffff;
    border-radius: ${(props) => props.theme.borderRadius || '8px'};
    position: absolute;
    top: 20px;
    left: 4%;
    right: 4%;
    width: auto;
    margin: auto;
    transform: scale(0.95);
    transform-origin: 100% 0;
    opacity: 0;
    pointer-events: none;
    transition: 250ms;
    transition-property: transform, opacity;
    z-index: 99999;
    ${(props) =>
      props.visible &&
      css`
        transform: scale(1);
        opacity: 1;
        pointer-events: auto;
      `}
  }
`;

const MenuBody = styled.ul`
  display: none;
  flex-direction: column;
  list-style: none;
  margin: 16px 16px 0;
  max-height: 80vh;
  overflow: scroll;
  overflow-x: hidden;

  @media (max-width: ${ScreenSizes.md}) {
    display: flex;
  }
`;

const MenuSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 48%);
  column-gap: 4%;
  row-gap: 30px;
  margin-bottom: 30px;

  @media (max-width: 340px) {
    grid-template-columns: repeat(1, 100%);
    column-gap: 0;
  }
`;

const MobileMenuButton = styled.a`
  display: none;
  height: 16px;
  @media (max-width: ${ScreenSizes.md}) {
    display: block;
  }
`;

const MenuCloseButton = styled.button`
  display: none;
  position: absolute;
  top: 6px;
  right: 6px;
  border: none;
  outline: none;
  padding: 0;
  background: none;
  @media (max-width: ${ScreenSizes.md}) {
    display: block;
  }
`;

const MobileCTA = styled.div`
  margin-top: 30px;
  margin-bottom: 16px;
  a {
    width: 100%;
  }
`;

const MenuFooter = styled.div`
  display: none;
  background-color: #f9fafd;
  margin-top: 30px;
  padding-top: 20px;
  padding-right: 16px;
  padding-left: 16px;

  @media (max-width: ${ScreenSizes.md}) {
    display: block;
  }
`;

export const MobileMenu: React.FC<{isOpen: boolean; setOpen: any}> = ({isOpen, setOpen}) => {
  const ref = useOnclickOutside(() => {
    setOpen(false);
  });
  const {language} = useI18next();
  const [origin, setOrigin] = useState(links.landing);

  return (
    <>
      <MobileMenuButton
        href="#"
        title="Open mobile navigation"
        onClick={(e) => {
          e.preventDefault();
          setOpen(true);
        }}>
        <MenuIcon />
      </MobileMenuButton>
      <MenuWrapper visible={isOpen} ref={ref}>
        <MenuCloseButton
          onClick={(e) => {
            e.preventDefault();
            setOpen(false);
          }}>
          <CloseIcon />
        </MenuCloseButton>
        <MenuBody>
          <Integrations itemClick={() => setOpen(false)} />
          <UseCases
            itemClick={() => setOpen(false)}
            title={
              <SectionHeader>
                <Trans>Use cases</Trans>
              </SectionHeader>
            }
          />
          {/*
          <FreeTools itemClick={() => setOpen(false)} />
          <StableCoin itemClick={() => setOpen(false)} />*/}
          <SectionHeader>
            <Trans>Payment methods</Trans>
          </SectionHeader>
          <MenuSection>
            <Item>
              <MenuItemIcon name="card" />
              <InternalPageLink
                slug="payment-methods/credit-cards"
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>Cards</Trans>
              </InternalPageLink>
            </Item>
            <EWallets onClick={() => setOpen(false)} />
            <PeerToPeer onClick={() => setOpen(false)} />
            <BankTransfer onClick={() => setOpen(false)} />
            <Item>
              <MenuItemIcon name="payment-methods" />
              <InternalPageLink
                slug="payment-methods"
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>View all</Trans>
              </InternalPageLink>
            </Item>
          </MenuSection>
          <SectionHeader>
            <Trans>Integrations</Trans>
          </SectionHeader>
          <MenuSection>
            <EcommercePlatforms onClick={() => setOpen(false)} />
          </MenuSection>
          <SectionHeader>
            <Trans>Resources</Trans>
          </SectionHeader>
          <MenuSection>
            <Item>
              <MenuItemIcon name="documentation" />
              <AnchorLink href={links.docs} target="_blank">
                <Trans>Docs</Trans>
              </AnchorLink>
            </Item>
            <Item>
              <MenuItemIcon name="support" />
              <AnchorLink href={links.support} target="_blank">
                <Trans>Support</Trans>
              </AnchorLink>
            </Item>
            <Item>
              <MenuItemIcon name="legal" />
              <InternalPageLink
                slug={'payments-glossary'}
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>Glossary</Trans>
              </InternalPageLink>
            </Item>
            <Item>
              <MenuItemIcon name="calculator" />
              <InternalPageLink
                slug={'interchange-fee-calculator'}
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>Interchange Fee Calculator</Trans>
              </InternalPageLink>
            </Item>
            <Item>
              <MenuItemIcon name="recurring" />
              <InternalPageLink
                slug={'affiliate-program'}
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>Become a MONEI Affiliate</Trans>
              </InternalPageLink>
            </Item>
          </MenuSection>
        </MenuBody>
        <MenuFooter>
          <MenuSection>
            <Item>
              <MenuItemIcon name="pricing" />
              <InternalPageLink
                slug="pricing"
                activeClassName="active"
                onClick={() => setOpen(false)}>
                <Trans>Pricing</Trans>
              </InternalPageLink>
            </Item>
            <Item>
              <MenuItemIcon name="blog" />
              <AnchorLink
                onClick={() => setOpen(false)}
                target="_self"
                href={`${origin + targetLanguage[(language as Language) || 'en']}/blog/`}>
                <Trans>Blog</Trans>
              </AnchorLink>
            </Item>
          </MenuSection>
          <MobileCTA>
            <ButtonLink variant="light" href={links.dashboard}>
              <Trans>Dashboard</Trans>
            </ButtonLink>
          </MobileCTA>
        </MenuFooter>
      </MenuWrapper>
    </>
  );
};
