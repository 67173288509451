import React from 'react';
import styled, {keyframes} from 'styled-components';

const bounceDelay = keyframes`
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
`;

export const Wrapper = styled.div<{color?: string}>`
  text-align: center;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    width: 10px;
    height: 10px;
    background-color: ${props => props.color || '#ffffff'};
    border-radius: 100%;
    display: inline-block;
    margin: 5px;
    animation: ${bounceDelay} 1.4s infinite ease-in-out both;
  }
  div:nth-child(1) {
    animation-delay: -0.32s;
  }
  div:nth-child(2) {
    left: 8px;
    animation-delay: -0.16s;
  }
`;

export const Loader: React.FC<{color?: string}> = ({color}) => (
  <Wrapper color={color}>
    <div />
    <div />
    <div />
  </Wrapper>
);
