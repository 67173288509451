import React from 'react';
import {AnchorLink} from './AnchorLink';
import download_android from 'images/google_play_store_badge_EN.svg';

export const DownloadOnGooglePlay: React.FC = () => {
  return (
    <AnchorLink
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.monei.moneibusiness">
      <img src={download_android} alt="Members of The European Payments Council" height={50} />
    </AnchorLink>
  );
};

export const AndroidDownloadLink: React.FC = ({children}) => {
  return (
    <AnchorLink
      target="_blank"
      href="https://play.google.com/store/apps/details?id=com.monei.moneibusiness">
      {children}
    </AnchorLink>
  );
};
