import styled from 'styled-components';
import {colors} from '../../global.config';
import {ScreenSizes} from '../../../types/responsive';

export const SectionHeader = styled.p`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 8px;

  @media (max-width: ${ScreenSizes.md}) {
    font-weight: 600;
    font-size: 12px;
    color: #98999c;
    margin-top: 0;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`;

export const Item = styled.li`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #4c5065;

  svg {
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  a {
    display: block;
    text-decoration: none;
    transition: 250ms color;

    :hover {
      color: ${colors.pink};
    }

    &.active {
      color: ${colors.pink};
    }
  }
`;

export const FeaturesList = styled.ul<{isLast?: boolean}>`
  padding: 0;
  list-style: none;
  margin: 0;

  li {
    margin-bottom: 24px;
    align-items: start;
  }

  @media (max-width: ${ScreenSizes.md}) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 8px;
  }

  @media (max-width: 340px) {
    grid-template-columns: repeat(1, 100%);
    column-gap: 0;
  }
`;
