import React from 'react';
import styled from 'styled-components';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {ButtonLink} from 'components/Button';
import {Content} from 'components/Content';
import bizum_plus_monei from 'images/bizum_plus_monei.svg';

export const Banner = styled.div`
  background-color: #30373b;
  color: #ffffff;
  padding: 0.4375rem 0;
  min-height: 3.25rem;
  position: sticky;
  top: 0;
  z-index: 100;
`;

export const BannerContent = styled(Content)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: 425px) {
    flex-direction: column;
    padding: 0 1rem;
  }
`;

export const Logos = styled.img`
  margin-bottom: 0;

  @media (max-width: 768px) {
    height: 1.875rem;
    margin-bottom: 0.25rem;
  }
`;

export const Text = styled.p`
  margin: 0 2.875rem;

  @media (max-width: 425px) {
    font-size: 0.75rem;
    margin: 0;
  }

  @media (max-width: 768px) {
    text-align: center;
  }
`;

export const Medium = styled.span`
  font-weight: 500;
`;

export const Bold = styled.span`
  font-weight: 700;
`;

export const BannerButton = styled(ButtonLink)`
  font-size: 0.875rem;
  line-height: 2.25rem;
  height: 2.25rem;
  min-width: 9.75rem;

  @media (max-width: 768px) {
    margin-top: 0.25rem;
    line-height: 1.9375rem;
    height: 1.875rem;
    min-width: 9.125rem;
  }
`;

export const StickyBanner: React.FC = () => {
  const {language} = useI18next();
  return (
    <Banner>
      <BannerContent>
        <Logos src={bizum_plus_monei} alt="Bizum + MONEI" width={268} />
        <Text>
          <Trans>
            Accept <Medium>Bizum</Medium> payments in your physical store with{' '}
            <Bold>MONEI Pay</Bold>
          </Trans>
        </Text>
        <BannerButton
          href={`/monei-pay/?utm_source=web&utm_medium=banner&utm_campaign=bizumbanner${language.toUpperCase()}`}
          variant="light">
          <Trans>Learn More</Trans>
        </BannerButton>
      </BannerContent>
    </Banner>
  );
};
