import React, {useEffect, useState} from 'react';
import {useI18next} from 'gatsby-plugin-react-i18next';
import {AnchorLink} from '../AnchorLink';
import {GatsbyLinkProps} from 'gatsby';
import {Language, targetLanguage} from '../../../locales/types';
import {links} from '../../global.config';

export type PrismicPageType =
  | 'compare-payment-gateways'
  | 'press'
  | 'kit-digital-pasarela-de-pagos-monei'
  | 'we-are-hiring'
  | 'payment-method-integrations-ecommerce-platforms';

export const PrismicPage: React.FC<{slug: PrismicPageType} & Partial<GatsbyLinkProps<any>>> = ({
  slug,
  children,
  ...props
}) => {
  const {language} = useI18next();
  const [origin, setOrigin] = useState(links.landing);
  useEffect(() => {
    setOrigin(window.location.origin);
  }, []);
  return (
    <AnchorLink
      target="_self"
      href={`${origin + targetLanguage[(language as Language) || 'en']}/page/${slug}/`}
      {...props}>
      {children}
    </AnchorLink>
  );
};
