import React from 'react';
import styled from 'styled-components';

interface IProps {
  disabled?: boolean;
  external?: boolean;
  nofollow?: boolean;
  target?: string;
}

const Link = styled.a<IProps>`
  pointer-events: ${(props) => (props.disabled ? 'none' : 'inherit')};
`;

export const AnchorLink: React.FC<IProps & React.AnchorHTMLAttributes<HTMLAnchorElement>> = ({
  href = '#',
  onClick,
  external,
  target,
  ...props
}) => {
  if (external || href.startsWith('mailto:')) {
    return (
      <Link
        rel="noopener noreferrer nofollow"
        href={href}
        target={target ? target : '_blank'}
        aria-disabled={props.disabled}
        {...props}
      />
    );
  }
  return (
    <Link
      href={href}
      aria-disabled={props.disabled}
      {...props}
      target={target ? target : '_blank'}
    />
  );
};
