import styled from 'styled-components';

export default styled.span`
  font-size: 10px;
  font-weight: 600;
  color: #5e80d2;
  margin: 0 0 0 6px;
  @media (max-width: 1023px) {
    display: block;
  }
`;
