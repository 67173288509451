import styled from "styled-components";
import { StyledDiv } from "./StyledSx";
import { ScreenSizes } from "../../types/responsive";

type Props = {
  columns?: number;
  columnsLg?: number;
  columnsMd?: number;
  columnsSm?: number;
  columnsXs?: number;
  gap?: number;
  colSize?: string;
};

export default styled(StyledDiv)<Props>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.columns}, ${(props) => props.colSize || '1fr'});
  grid-gap: ${(props) => props.gap}px;
  @media (max-width: ${ScreenSizes.lg}) {
    grid-template-columns: repeat(${(props) => props.columnsLg}, ${(props) => props.colSize || '1fr'});
  }
  @media (max-width: ${ScreenSizes.md}) {
    grid-template-columns: repeat(${(props) => props.columnsMd}, ${(props) => props.colSize || '1fr'});
  }
  @media (max-width: ${ScreenSizes.sm}) {
    grid-template-columns: repeat(${(props) => props.columnsSm}, ${(props) => props.colSize || '1fr'});
  }
  @media (max-width: ${ScreenSizes.xs}) {
    width: 100%;
    grid-template-columns: repeat(${(props) => props.columnsXs}, ${(props) => props.colSize || '1fr'});
  }
`;
