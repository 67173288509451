import { Button } from "components/Button";
import { Field } from "components/Field";
import { Form, Formik } from "formik";
import { Trans, useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { links } from "global.config";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import styled from "styled-components";
import createPersistedState from "use-persisted-state";
import * as yup from "yup";
import { getClientId } from "utils/helpers";

type Props = {
  footer?: boolean;
};
type FormValues = {
  email: string;
  name?: string;
};

const initialValues: FormValues = {
  email: ''
};

Modal.setAppElement(`#___gatsby`);
Modal.defaultStyles = {};

const modalCloseTimeout = 300;

const useSubscriptionState = createPersistedState('subscription');

const ModalForm = styled(Form)`
  text-align: center;
  margin: 0;
`;

export const CloseIcon = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  transition: opacity 0.2s;
  &:after,
  &:before {
    content: '';
    height: 20px;
    width: 20px;
    border-top: 1px solid;
    position: absolute;
    top: 7px;
    right: -8px;
    transform: rotate(-45deg);
  }

  &:before {
    right: 6px;
    transform: rotate(45deg);
  }

  &:hover {
    opacity: 0.5;
  }
`;

const FooterContent = styled.div`
  width: 232px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const FooterForm = styled(Form)``;

export const SubscriptionForm: React.FC<Props> = ({footer}) => {
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isDone, setDone] = useState(false);
  const [userId, setUserId] = useState<string | undefined>(undefined);
  const {language, path} = useI18next();
  const {t} = useTranslation();
  const [subscription, setSubscription] = useSubscriptionState({
    lastRequest: 0,
    isSubscribed: false
  });
  const namePlaceholder = t('Your name');
  const emailPlaceholder = t('Email');
  const shouldShowModal =
    !subscription.isSubscribed && (new Date().valueOf() - subscription.lastRequest) / 1000 > 648000;

  const openModal = () => setOpen(true);

  const closeModal = () => {
    setSubscription({isSubscribed: false, lastRequest: new Date().valueOf()});
    setOpen(false);
  };

  useEffect(() => {
    if (footer || !shouldShowModal) return;
    document.addEventListener('mouseleave', openModal);
    return () => document.removeEventListener('mouseleave', openModal);
  }, [shouldShowModal, footer]);

  useEffect(() => {
    getClientId().then((userId) => {
      setUserId(userId);
    });
  }, [])


  const handleSubmit = async ({email, name}: FormValues) => {
    setLoading(true);
    try {
      const body = JSON.stringify({
        email,
        firstName: name,
        topics: ['MonthlyNewsletter'],
        language,
        userId,
      });
      fetch(links.pinpointApi + `user/subscribe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body}).then(() => {
        setLoading(false);
        setDone(true);
        setSubscription({isSubscribed: true, lastRequest: 0});
        setTimeout(() => {
          setOpen(false);
        }, 2000);
      })
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const validationSchema = yup.object().shape({
    email: yup.string().email(t('should be a valid email')).required(t('email is required'))
  });

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}>
      {footer ? (
        <FooterContent>
          <FooterForm>
            {isDone ? (
              <Trans parent="p">Thank you! We will keep in touch.</Trans>
            ) : (
              <>
                <Field name="name" placeholder={namePlaceholder} />
                <Field name="email" placeholder={emailPlaceholder} type="email" />
                <Button type="submit" variant="light" block loading={isLoading}>
                  <Trans>Subscribe</Trans>
                </Button>
              </>
            )}
          </FooterForm>
        </FooterContent>
      ) : (
        <Modal
          isOpen={isOpen}
          contentLabel="Modal"
          closeTimeoutMS={modalCloseTimeout}
          onRequestClose={closeModal}>
          <ModalForm noValidate>
            <CloseIcon onClick={closeModal} />
            <Trans parent="h2">MONEI monthly</Trans>
            {isDone ? (
              <Trans parent="p">Thank you! We will keep in touch.</Trans>
            ) : (
              <>
                <Trans parent="p">
                  Subscribe to get latest news, e-commerce tips, and helpful resources from around
                  the web.
                </Trans>
                <Field name="userAttributes.Name" placeholder={namePlaceholder} />
                <Field name="address" placeholder={emailPlaceholder} type="email" />
                <Button type="submit" variant="light" block loading={isLoading}>
                  <Trans>Subscribe</Trans>
                </Button>
              </>
            )}
          </ModalForm>
        </Modal>
      )}
    </Formik>
  );
};
