// The idea of this type is to provide a way to define responsive values in styled objects
// by adding  sx?: { [x: string]: Responsive} to the props of the styled object
// this is inspired on material ui https://mui.com/material-ui/react-box/#the-sx-prop

export const containerSize = '1210px'
export const halfContainerSize = '605px'

export enum ScreenSizes {
  xs = '600px',
  sm = '768px',
  ms = '900px',
  md = '1024px',
  lg = '1280px',
  xl = '1440px'
}

export type Responsive = {
  xs?: string | number; //max-width 600px
  sm?: string | number; //max-width 768px
  md?: string | number; //max-width 1024px
  lg?: string | number; //max-width 1280px
  xl?: string | number; //max-width 1440px
  all?: string | number
};

export type Sx = {[x: string]: Responsive | string | number};
