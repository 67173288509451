import {Loader} from 'components/Loader';
import React from 'react';
import styled, {css} from 'styled-components';
import {useDebounce} from 'use-debounce';
import {AnchorLink} from './AnchorLink';
import { Sx } from "../../types/responsive";

export type ButtonProps = {
  variant?: 'light' | 'dark' | 'darkLight';
  block?: boolean;
  loading?: boolean;
};

const buttonStyle = css<ButtonProps>`
  border: none;
  display: inline-block;
  border-radius: ${(props) => props.theme.borderRadius};
  cursor: pointer;
  padding: 0 20px;
  font-size: 1em;
  position: relative;
  text-decoration: none !important;
  color: inherit;
  text-align: center;
  white-space: nowrap;
  font-weight: 500;
  transition: all 0.2s;
  box-shadow: ${(props) => props.theme.boxShadow};
  background: #ffffff;
  line-height: 43px;
  height: 43px;
  min-width: 232px;
  outline: none;
  &:hover {
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    transform: translateY(-1px);
    color: inherit;
  }
  &:active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    transform: translateY(1px);
  }
  ${(props) =>
    props.block &&
    css`
      display: block;
      width: 100%;
    `}
  ${(props) =>
    props.variant === 'light' &&
    css`
      background-image: linear-gradient(-135deg, #aab5ea 0%, #41d7bd 100%);
      color: #ffffff !important;
    `}
  ${(props) =>
    props.variant === 'darkLight' &&
    css`
      color: rgba(0, 0, 0, 0.7) !important;
    `}
  ${(props) =>
    props.variant === 'dark' &&
    css`
      background-image: linear-gradient(-135deg, #d5a5fd 0%, #dc84be 100%);
      color: #ffffff !important;
    `}
`;

export const ButtonLink = styled(AnchorLink)<ButtonProps>`
  ${buttonStyle}
`;

const StyledButton = styled.button<ButtonProps>`
  ${buttonStyle}
`;

export const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
  loading,
  disabled,
  children,
  ...props
}) => {
  const [isLoading] = useDebounce(loading, 250);
  return (
    <StyledButton {...props} disabled={disabled || loading}>
      {isLoading ? <Loader /> : <span>{children}</span>}
    </StyledButton>
  );
};
