import {configVars} from '../global.config';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import 'moment/locale/en-gb';
import 'moment/locale/es';
import 'moment/locale/fr';

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export function getArrayOfLength<T>(length: number, defaultValue: T): T[] {
  return Array.apply(defaultValue, Array(length)).map(() => {
    return defaultValue as T;
  });
}

export const getClientId = async () => {
  if (!configVars.measurementId) {
    return undefined;
  }
  window.dataLayer = window.dataLayer || [];
  function gtag(...args: any) {
    window.dataLayer.push(arguments);
  }
  return new Promise<string>((resolve) => {
    gtag('get', configVars.measurementId, 'client_id', resolve);
  });
};

export const setMomentLocale = (name: string) => {
  moment.locale(name);
  // @ts-ignore
  momentTZ.updateLocale(name, moment.localeData()._config);
  momentTZ.locale(name);
};

export const getPolicyUrl = (name: string, locale: string) => {
  locale = locale.split('-')[0];
  return `https://assets.monei.com/docs/${locale}/${name}`;
};

export const normalizeString = (str: string|undefined) => {
  if (!str) return '';
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
