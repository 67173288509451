import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {colors} from 'global.config';
import {Trans} from 'gatsby-plugin-react-i18next';
import {ScreenSizes} from '../../../types/responsive';
import {Resources} from './resources/Resources';

const MenuItem = styled.li`
  display: flex;
  margin: 0 25px;
  position: relative;

  a {
    display: block;
    position: relative;
    text-decoration: none;
    transition: 250ms color;
    color: inherit;

    :hover {
      color: ${colors.pink};
    }

    &.active {
      color: ${colors.pink};
    }
  }
`;

const LinkWrapper = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  cursor: pointer;

  :hover {
    color: ${colors.pink};
  }
`;

const Menu = styled.div<{visible: boolean}>`
  width: auto;
  min-width: 330px;
  position: absolute;
  background: #ffffff;
  border-radius: 14px;
  z-index: 99;
  left: -125px;
  top: 80px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: opacity 0.1s ease, transform 0.1s ease, visibility 0.1s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 24px;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `};

  @media (max-width: ${ScreenSizes.lg}) {
    left: -105px;
  }
`;

const TriangleArrow = styled.div<{visible: boolean}>`
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 3px 0 0 0;
  background-color: white;
  box-shadow: -3px -3px 5px rgb(82 95 127 / 4%);
  z-index: 100;
  transition-property: transform;
  transition-duration: 125ms;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      transform: rotate(45deg);
    `}
`;

export const ResourcesSelector: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MenuItem onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
      <LinkWrapper>
        <Trans>Resources</Trans>
      </LinkWrapper>
      <Menu visible={isOpen}>
        <TriangleArrow visible={isOpen} />
        <Resources itemClick={() => setOpen(false)} />
      </Menu>
    </MenuItem>
  );
};
