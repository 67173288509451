import React from 'react';

type Props = {};

export const MenuIcon: React.FC<Props> = () => {
  return (
    <svg width="20" height="16" viewBox="0 0 20 16">
      <title>Open mobile navigation</title>
      <g fill="currentColor" fillRule="evenodd">
        <rect width="20" height="3" rx="1" />
        <rect y="6" width="20" height="3" rx="1" />
        <rect y="12" width="20" height="3" rx="1" />
      </g>
    </svg>
  );
};
