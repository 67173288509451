// extend this styled component to add responsive props
// example: sx={{ fontSize: {xl: '3rem', xs: '2rem'}, margin: '10px }}
// value can be either a string or an object
// non specified sizes will take the greatest available size value
// take care of not passing 0 as a value but '0'
import styled from 'styled-components';
import {Responsive, ScreenSizes, Sx} from '../../types/responsive';

export const StyledDiv = styled.div<{sx?: Sx}>`
  ${(props) =>
    props.sx &&
    Object.keys(props.sx).map((key: any) => ({
      ...(props.sx?.[key] && typeof props.sx[key] === 'string'
        ? {[key]: props.sx[key] + '!important'}
        : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
    }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xl
          ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).lg
          ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).md
          ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).sm
          ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xs
          ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
          : {})
      }))};
  }
`;

export const StyledP = styled.p<{sx?: Sx}>`
  ${(props) =>
    props.sx &&
    Object.keys(props.sx).map((key: any) => ({
      ...(props.sx?.[key] && typeof props.sx[key] === 'string'
        ? {[key]: props.sx[key] + '!important'}
        : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
    }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xl
          ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).lg
          ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).md
          ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).sm
          ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xs
          ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
          : {})
      }))};
  }
`;

export const StyledImg = styled.img<{sx?: Sx}>`
  ${(props) =>
    props.sx &&
    Object.keys(props.sx).map((key: any) => ({
      ...(props.sx?.[key] && typeof props.sx[key] === 'string'
        ? {[key]: props.sx[key] + '!important'}
        : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
    }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xl
          ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).lg
          ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).md
          ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).sm
          ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xs
          ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
          : {})
      }))};
  }
`;

export const StyledSection = styled.section<{sx?: Sx}>`
  ${(props) =>
    props.sx &&
    Object.keys(props.sx).map((key: any) => ({
      ...(props.sx?.[key] && typeof props.sx[key] === 'string'
        ? {[key]: props.sx[key] + '!important'}
        : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
    }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xl
          ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).lg
          ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).md
          ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).sm
          ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xs
          ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
          : {})
      }))};
  }
`;

export const StyledLi = styled.li<{sx?: Sx}>`
  ${(props) =>
    props.sx &&
    Object.keys(props.sx).map((key: any) => ({
      ...(props.sx?.[key] && typeof props.sx[key] === 'string'
        ? {[key]: props.sx[key] + '!important'}
        : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
    }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xl
          ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).lg
          ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).md
          ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).sm
          ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
          : {})
      }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
      props.sx &&
      Object.keys(props.sx).map((key: any) => ({
        ...((props.sx?.[key] as Responsive).xs
          ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
          : {})
      }))};
  }
`;

export const StyledUl = styled.ul<{sx?: Sx}>`
  ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...(props.sx?.[key] && typeof props.sx[key] === 'string'
      ? {[key]: props.sx[key] + '!important'}
      : {[key]: (props.sx?.[key] as Responsive).all + '!important'})
  }))};
  @media (max-width: ${ScreenSizes.xl}) {
    ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...((props.sx?.[key] as Responsive).xl
      ? {[key]: (props.sx?.[key] as Responsive).xl + '!important'}
      : {})
  }))};
  }
  @media (max-width: ${ScreenSizes.lg}) {
    ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...((props.sx?.[key] as Responsive).lg
      ? {[key]: (props.sx?.[key] as Responsive).lg + '!important'}
      : {})
  }))};
  }
  @media (max-width: ${ScreenSizes.md}) {
    ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...((props.sx?.[key] as Responsive).md
      ? {[key]: (props.sx?.[key] as Responsive).md + '!important'}
      : {})
  }))};
  }
  @media (max-width: ${ScreenSizes.sm}) {
    ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...((props.sx?.[key] as Responsive).sm
      ? {[key]: (props.sx?.[key] as Responsive).sm + '!important'}
      : {})
  }))};
  }
  @media (max-width: ${ScreenSizes.xs}) {
    ${(props) =>
  props.sx &&
  Object.keys(props.sx).map((key: any) => ({
    ...((props.sx?.[key] as Responsive).xs
      ? {[key]: (props.sx?.[key] as Responsive).xs + '!important'}
      : {})
  }))};
  }
`;
