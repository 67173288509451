export type Language = 'es' | 'en' | 'ca' | 'de' | 'fr' | 'pt' | 'it';
export type LanguageType = {es: string} & {
  [x in Language]?: string;
};
export enum LanguageEnum {
  es = 'es',
  en = 'en',
  ca = 'ca',
  de = 'de',
  fr = 'fr',
  pt = 'pt',
  it = 'it'
}
export const targetLanguage: Record<Language, string> = {
  it: '',
  de: '',
  pt: '',
  ca: '/es',
  en: '',
  es: '/es',
  fr: '/fr'
}
