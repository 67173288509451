import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {colors} from 'global.config';
import {Trans} from 'gatsby-plugin-react-i18next';
import {MenuItemIcon} from './MenuItemIcon';
import {ViewAll} from './menu-links/ViewAll';
import {BankTransfer} from './menu-links/BankTransfer';
import {EcommercePlatforms} from './menu-links/EcommercePlatforms';
import {PeerToPeer} from './menu-links/PeerToPeer';
import {EWallets} from './menu-links/EWallets';
import Grid from 'components/Grid';
import {Item, SectionHeader} from './Styled';
import {Integrations} from './features-links/Integrations';
import {FreeTools} from './features-links/FreeTools';
import {ScreenSizes} from '../../../types/responsive';
import {InternalPageLink} from 'components/links/Pages';

const MenuItem = styled.li`
  display: flex;
  margin: 0 25px;
  position: relative;

  a {
    display: block;
    position: relative;
    text-decoration: none;
    transition: 250ms color;
    color: inherit;

    :hover {
      color: ${colors.pink};
    }

    &.active {
      color: ${colors.pink};
    }
  }
`;

const LinkWrapper = styled.div`
  padding-bottom: 40px;
  padding-top: 40px;
  cursor: pointer;

  :hover {
    color: ${colors.pink};
  }
`;

const Menu = styled.div<{visible: boolean}>`
  width: auto;
  min-width: 720px;
  position: absolute;
  background: #ffffff;
  border-radius: 14px;
  z-index: 99;
  left: -324px;
  top: 80px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: opacity 0.1s ease, transform 0.1s ease, visibility 0.1s;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translateY(0);
    `};

  @media (max-width: ${ScreenSizes.lg}) {
    left: -280px;
  }
`;

const MenuBody = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
`;

const LeftSection = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 14px 24px 0 24px;

  li {
    margin-bottom: 16px;
  }
`;

const RightSection = styled.div`
  padding: 14px 24px 0 24px;
  background-color: #f9fafd;
  border-left: 1px solid #e8e9ec;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const Separator = styled.div`
  height: 1px;
  background-color: #e8e9ec;
`;

const TriangleArrow = styled.div<{visible: boolean}>`
  position: absolute;
  top: -8px;
  left: calc(50% - 8px);
  width: 16px;
  height: 16px;
  transform: rotate(45deg);
  border-radius: 3px 0 0 0;
  background-color: white;
  box-shadow: -3px -3px 5px rgb(82 95 127 / 4%);
  z-index: 100;
  transition-property: transform;
  transition-duration: 125ms;

  ${(props) =>
    props.visible &&
    css`
      opacity: 1;
      transform: rotate(45deg);
    `}
`;

export const FeaturesSelector: React.FC = () => {
  const [isOpen, setOpen] = useState(false);

  return (
    <MenuItem
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(false)}
      role="listitem">
      <LinkWrapper>
        <Trans>Features</Trans>
      </LinkWrapper>
      <Menu visible={isOpen}>
        <TriangleArrow visible={isOpen} />
        <MenuBody>
          <LeftSection>
            <SectionHeader>
              <InternalPageLink slug="payment-methods" onClick={() => setOpen(false)}>
                <Trans>Payment methods</Trans>
              </InternalPageLink>
            </SectionHeader>
            <Grid columns={2} gap={16}>
              <Item>
                <MenuItemIcon name="card" />
                <InternalPageLink
                  slug="payment-methods/credit-cards"
                  activeClassName="active"
                  onClick={() => setOpen(false)}>
                  <Trans>Cards</Trans>
                </InternalPageLink>
              </Item>
              <EWallets onClick={() => setOpen(false)} />
              <PeerToPeer onClick={() => setOpen(false)} />
              <BankTransfer onClick={() => setOpen(false)} />
              <ViewAll onClick={() => setOpen(false)} />
            </Grid>
            <SectionHeader>
              <Trans>E-commerce platforms</Trans>
            </SectionHeader>
            <Grid columns={2} gap={16} colSize={'50%'}>
              <EcommercePlatforms onClick={() => setOpen(false)} />
            </Grid>
          </LeftSection>
          <RightSection>
            <Integrations itemClick={() => setOpen(false)} />
            {/*
            <Separator />
            <FreeTools itemClick={() => setOpen(false)} />
            <Separator />
            <StableCoin itemClick={() => setOpen(false)} />*/}
          </RightSection>
        </MenuBody>
      </Menu>
    </MenuItem>
  );
};
