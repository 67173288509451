import React from 'react';
import {MenuItemIcon} from '../MenuItemIcon';
import {Trans} from 'gatsby-plugin-react-i18next';
import {Item} from '../Styled';
import {InternalPageLink} from 'components/links/Pages';

export const ViewAll: React.FC<{onClick: VoidFunction}> = ({onClick}) => {
  return (
    <>
      <Item>
        <MenuItemIcon name="payment-methods" />
        <InternalPageLink slug="payment-methods" activeClassName="active" onClick={onClick}>
          <Trans>View all</Trans>
        </InternalPageLink>
      </Item>
    </>
  );
};
