import React from 'react';
import {MenuItemIcon} from '../MenuItemIcon';
import {Item} from '../Styled';
import {InternalPageLink} from 'components/links/Pages';

export const BankTransfer: React.FC<{onClick: VoidFunction}> = ({onClick}) => (
  <>
    <Item>
      <MenuItemIcon name="multibanco" />
      <InternalPageLink
        slug="payment-methods/multibanco"
        activeClassName="active"
        onClick={onClick}>
        Multibanco
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="mb-way" />
      <InternalPageLink slug="payment-methods/mb-way" activeClassName="active" onClick={onClick}>
        MB Way
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="sepa" />
      <InternalPageLink
        slug="payment-methods/sepa-direct-debit"
        activeClassName="active"
        onClick={onClick}>
        SEPA Direct Debit
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="bancontact" />
      <InternalPageLink
        slug="payment-methods/bancontact"
        activeClassName="active"
        onClick={onClick}>
        Bancontact
      </InternalPageLink>
    </Item>
  </>
);
