import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import {Link} from 'gatsby-plugin-react-i18next';
import {Content} from 'components/Content';
import {Spacer} from 'components/Spacer';
import {MobileMenu} from './MobileMenu';
import {DesktopMenu} from './DesktopMenu';
import {containerSize} from '../../../types/responsive';

const LogoLink = styled(Link)`
  width: 141px;
  height: 31px;
  display: block;
  opacity: 0.6;
  transition: 250ms opacity;
  :hover {
    opacity: 0.8;
  }
`;

const Container = styled.header<{isNewLayout: boolean}>`
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 94px;
  ${(props) =>
    props.isNewLayout &&
    css`
      max-width: ${containerSize};
      margin: 0 auto;
      padding: 0 20px;
    `}
`;

const ContentHeader = styled(Content)`
  z-index: 12;
`;

export const Header: React.FC<{isNewLayout: boolean}> = ({isNewLayout}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <ContentHeader>
      <Container isNewLayout={isNewLayout}>
        <LogoLink to="/">
          <img src="https://assets.monei.com/images/logo.svg" alt="MONEI" />
        </LogoLink>
        <Spacer />
        <DesktopMenu />
        <MobileMenu isOpen={isOpen} setOpen={setOpen} />
      </Container>
    </ContentHeader>
  );
};
