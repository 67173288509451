import {Link} from 'gatsby-plugin-react-i18next';

import React, {PropsWithChildren} from 'react';

export type InternalPageSlug =
  | 'features'
  | 'features/payments-orchestration'
  | 'features/pay-by-link'
  | 'features/recurring-payments'
  | 'features/whatsapp-payments'
  | 'monei-pay'
  | 'monei-pay/qr'
  | 'monei-pay/qr-code-payment-restaurant'
  | 'monei-pay/pos-for-freelancers'
  | 'monei-pay/retail-POS-system'
  | 'monei-pay/pos-systems-for-taxis'
  | 'monei-pay/pos-systems-for-hair-salon'
  | 'monei-pay/food-truck-pos-system'
  | 'payment-methods'
  | 'payment-methods/cofidis'
  | 'payment-methods/klarna'
  | 'payment-methods/credit-cards'
  | 'payment-methods/google-pay'
  | 'payment-methods/apple-pay'
  | 'payment-methods/bizum'
  | 'payment-methods/paypal'
  | 'payment-methods/click-to-pay'
  | 'payment-methods/sepa-direct-debit'
  | 'payment-methods/ideal'
  | 'payment-methods/trustly'
  | 'payment-methods/giropay'
  | 'payment-methods/multibanco'
  | 'payment-methods/bancontact'
  | 'payment-methods/sofort'
  | 'payment-methods/sepa-request-to-pay'
  | 'payment-methods/credit-card'
  | 'payment-methods/mb-way'
  | '2checkout-alternative'
  | 'adyen-alternative'
  | 'affiliate-program'
  | 'andorra-payment-gateway'
  | 'apple-pay-for-prestashop'
  | 'apple-pay-for-shopify'
  | 'apple-pay-for-wix'
  | 'apple-pay-for-woocommerce'
  | 'bbva-shopify'
  | 'bizum-empresas'
  | 'bizum-for-prestashop'
  | 'bizum-for-shopify'
  | 'bizum-for-wix'
  | 'bizum-for-woocommerce'
  | 'blog'
  | 'buy-now-pay-later-for-prestashop'
  | 'cofidis'
  | 'cofidis-pay'
  | 'connect'
  | 'defensa-cliente'
  | 'google-pay-for-prestashop'
  | 'google-pay-for-shopify'
  | 'google-pay-for-wix'
  | 'google-pay-for-woocommerce'
  | 'index'
  | 'installment-payments-for-shopify'
  | 'installment-payments-for-wix'
  | 'installment-payments-for-woocommerce'
  | 'interchange-fee-calculator'
  | 'klarna-alternative'
  | 'magento-payment-gateway'
  | 'mollie-alternative'
  | 'notification-preferences'
  | 'omnichannel-payments-platform'
  | 'partners'
  | 'payment-gateway'
  | 'payment-gateway-spain'
  | 'payment-gateway-france'
  | 'payon-aci-alternative'
  | 'paypal-alternative'
  | 'paypal-for-prestashop'
  | 'paypal-for-shopify'
  | 'paypal-for-wix'
  | 'paypal-for-woocommerce'
  | 'pbc-ft-formulario-oci'
  | 'pci-dss'
  | 'prestashop-payment-gateway'
  | 'pricing'
  | 'privacy-policy'
  | 'redsys-alternative'
  | 'shopify-payment-gateway'
  | 'shopify-payments-alternative'
  | 'sla'
  | 'subscribe'
  | 'stripe-alternative'
  | 'terms-of-service'
  | 'visa-mastercard-for-prestashop'
  | 'visa-mastercard-for-shopify'
  | 'visa-mastercard-for-wix'
  | 'visa-mastercard-for-woocommerce'
  | 'wix-payment-gateway'
  | 'woocommerce-payment-gateway'
  | 'monei-connect'
  | 'tpv-virtual'
  | 'online-payments'
  | 'payments-glossary'
  | 'about-us';

type Props = {
  slug: InternalPageSlug | '';
  activeClassName?: string;
} & React.HTMLAttributes<HTMLAnchorElement> &
  PropsWithChildren<any>;

export const InternalPageLink: React.FC<Props> = ({slug, children, ...props}) => {
  const to = slug ? `/${slug}/` : '/';
  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};

export const PrestashopPaymentGatewayLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/prestashop-payment-gateway/">{children}</Link>
);
export const RedsysAlternativeLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/redsys-alternative/">{children}</Link>
);
export const PaymentsOrchestrationLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/features/payments-orchestration/">{children}</Link>
);
export const PricingLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/pricing/">{children}</Link>
);
export const CofidisPageLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/cofidis/">{children}</Link>
);

export const PaypalForPrestashopLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/paypal-for-prestashop/">{children}</Link>
);
export const GooglePayForPrestashopLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/google-pay-for-prestashop/">{children}</Link>
);
export const ApplePayForPrestashopLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/apple-pay-for-prestashop/">{children}</Link>
);
export const BizumForPrestashopLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/bizum-for-prestashop/">{children}</Link>
);
export const VisaMasterCardForPrestashopLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/visa-mastercard-for-prestashop/">{children}</Link>
);
export const MoneiPayLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/monei-pay/">{children}</Link>
);
export const MoneiPayQrLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/monei-pay/qr/">{children}</Link>
);
export const MoneiPayRestaurantsLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/monei-pay/qr-code-payment-restaurant/">{children}</Link>
);
export const BizumEmpresasLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/bizum-empresas/">{children}</Link>
);
export const ShopifyPaymentGatewayLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/shopify-payment-gateway/">{children}</Link>
);
export const PciDssLink: React.FC<PropsWithChildren<any>> = ({children}) => (
  <Link to="/pci-dss/">{children}</Link>
);
