import React from 'react';
import {useLocation} from '@reach/router';
import {useI18next} from 'gatsby-plugin-react-i18next';
import styled from 'styled-components';
import {Content} from './Content';
import {InternalPageLink, InternalPageSlug} from './links/Pages';

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
`;

const BreadcrumbItem = styled.div`
  display: inline-flex;
  font-size: 0.9rem;
  &a {
    white-space: nowrap;
  }
`;
type BreadcrumbItem = {label: string; link: InternalPageSlug | ''};

export const Breadcrumbs: React.FC = () => {
  const {pathname} = useLocation();
  const {t} = useI18next();
  const moneiPay: BreadcrumbItem = {label: 'MONEI Pay', link: 'monei-pay'};
  const paymentMethods: BreadcrumbItem = {label: t('Payment Methods'), link: 'payment-methods'};
  const googlePay: BreadcrumbItem = {label: t('Google Pay'), link: 'payment-methods/google-pay'};
  const applePay: BreadcrumbItem = {label: t('Apple Pay'), link: 'payment-methods/apple-pay'};
  const bizum: BreadcrumbItem = {label: t('Bizum'), link: 'payment-methods/bizum'};
  const klarna: BreadcrumbItem = {label: t('Klarna'), link: 'payment-methods/klarna'};
  const ideal: BreadcrumbItem = {label: t('iDEAL'), link: 'payment-methods/ideal'};
  const paypal: BreadcrumbItem = {label: t('PayPal'), link: 'payment-methods/paypal'};
  const clickToPay: BreadcrumbItem = {
    label: t('Click to Pay'),
    link: 'payment-methods/click-to-pay'
  };
  const creditCards: BreadcrumbItem = {
    label: t('Credit Card'),
    link: 'payment-methods/credit-cards'
  };
  const sepaDirectDebit: BreadcrumbItem = {
    label: t('SEPA Direct Debit'),
    link: 'payment-methods/sepa-direct-debit'
  };
  const bancontact: BreadcrumbItem = {label: t('Bancontact'), link: 'payment-methods/bancontact'};
  const sofort: BreadcrumbItem = {label: t('SOFORT'), link: 'payment-methods/sofort'};
  const sepaRequestToPay: BreadcrumbItem = {
    label: t('SEPA Request-to-Pay'),
    link: 'payment-methods/sepa-request-to-pay'
  };
  const multibanco: BreadcrumbItem = {label: t('Multibanco'), link: 'payment-methods/multibanco'};
  const features: BreadcrumbItem = {label: t('Features'), link: 'features'};
  const bcLabels: Record<string, BreadcrumbItem[]> = {
    features: [features],
    'monei-pay': [moneiPay],
    qr: [moneiPay, {label: t('QR Payments'), link: 'monei-pay/qr'}],
    'qr-code-payment-restaurant': [
      moneiPay,
      {label: t('QR Code Payment for Restaurants'), link: 'monei-pay/qr-code-payment-restaurant'}
    ],
    'pos-for-freelancers': [
      moneiPay,
      {label: t('POS for Freelancers'), link: 'monei-pay/pos-for-freelancers'}
    ],
    'retail-POS-system': [
      moneiPay,
      {label: t('Retail POS System'), link: 'monei-pay/retail-POS-system'}
    ],
    'pos-systems-for-taxis': [
      moneiPay,
      {label: t('Taxi POS'), link: 'monei-pay/pos-systems-for-taxis'}
    ],
    'pos-systems-for-hair-salon': [
      moneiPay,
      {
        label: t('POS System for hair and beauty salons'),
        link: 'monei-pay/pos-systems-for-hair-salon'
      }
    ],
    'food-truck-pos-system': [
      moneiPay,
      {label: t('Food Truck POS System'), link: 'monei-pay/food-truck-pos-system'}
    ],
    'payment-methods': [paymentMethods],
    klarna: [paymentMethods, klarna],
    'credit-cards': [paymentMethods, creditCards],
    'google-pay': [paymentMethods, googlePay],
    'apple-pay': [paymentMethods, applePay],
    bizum: [paymentMethods, bizum],
    paypal: [paymentMethods, paypal],
    'click-to-pay': [paymentMethods, clickToPay],
    'sepa-direct-debit': [paymentMethods, sepaDirectDebit],
    ideal: [paymentMethods, ideal],
    multibanco: [paymentMethods, multibanco],
    bancontact: [paymentMethods, bancontact],
    sofort: [paymentMethods, sofort],
    'sepa-request-to-pay': [paymentMethods, sepaRequestToPay],
    'google-pay-for-wix': [paymentMethods, googlePay, {label: t('Google Pay for Wix'), link: ''}],
    'google-pay-for-shopify': [
      paymentMethods,
      googlePay,
      {label: t('Google Pay for Shopify'), link: ''}
    ],
    'google-pay-for-woocommerce': [
      paymentMethods,
      googlePay,
      {label: t('Google Pay for WooCommerce'), link: ''}
    ],
    'google-pay-for-prestashop': [
      paymentMethods,
      googlePay,
      {label: t('Google Pay for PrestaShop'), link: ''}
    ],
    'apple-pay-for-wix': [paymentMethods, applePay, {label: t('Apple Pay for Wix'), link: ''}],
    'apple-pay-for-shopify': [
      paymentMethods,
      applePay,
      {label: t('Apple Pay for Shopify'), link: ''}
    ],
    'apple-pay-for-woocommerce': [
      paymentMethods,
      applePay,
      {label: t('Apple Pay for WooCommerce'), link: ''}
    ],
    'apple-pay-for-prestashop': [
      paymentMethods,
      applePay,
      {label: t('Apple Pay for PrestaShop'), link: ''}
    ],
    'bizum-for-wix': [paymentMethods, bizum, {label: t('Bizum for Wix'), link: ''}],
    'bizum-for-shopify': [paymentMethods, bizum, {label: t('Bizum for Shopify'), link: ''}],
    'bizum-for-woocommerce': [paymentMethods, bizum, {label: t('Bizum for WooCommerce'), link: ''}],
    'bizum-for-prestashop': [paymentMethods, bizum, {label: t('Bizum for PrestaShop'), link: ''}],
    'paypal-for-wix': [paymentMethods, paypal, {label: t('Paypal for Wix'), link: ''}],
    'paypal-for-shopify': [paymentMethods, paypal, {label: t('Paypal for Shopify'), link: ''}],
    'paypal-for-woocommerce': [
      paymentMethods,
      paypal,
      {label: t('Paypal for WooCommerce'), link: ''}
    ],
    'paypal-for-prestashop': [
      paymentMethods,
      paypal,
      {label: t('Paypal for PrestaShop'), link: ''}
    ],
    'visa-mastercard-for-wix': [
      paymentMethods,
      creditCards,
      {label: t('Visa-Mastercard for Wix'), link: ''}
    ],
    'visa-mastercard-for-shopify': [
      paymentMethods,
      creditCards,
      {label: t('Visa-Mastercard for Shopify'), link: ''}
    ],
    'visa-mastercard-for-woocommerce': [
      paymentMethods,
      creditCards,
      {label: t('Visa-Mastercard for WooCommerce'), link: ''}
    ],
    'visa-mastercard-for-prestashop': [
      paymentMethods,
      creditCards,
      {label: t('Visa-Mastercard for PrestaShop'), link: ''}
    ],
    'payments-orchestration': [features, {label: t('Payments orchestration'), link: ''}],
    'pay-by-link': [features, {label: t('Pay by Link'), link: ''}],
    'recurring-payments': [features, {label: t('Recurring Payments'), link: ''}],
    subscriptions: [features, {label: t('Subscription Payment Service'), link: ''}]
  };
  const lastSegment = pathname
    .split('/')
    .filter((item) => !!item)
    .pop();

  const breadcrumbGroup = lastSegment && bcLabels[lastSegment];

  if (!breadcrumbGroup) {
    return null;
  }

  const breadcrumbs = [{label: t('Home'), link: ''}, ...breadcrumbGroup];

  return (
    <Content sx={{height: '35px'}}>
      <Wrapper>
        {breadcrumbs.map((bc, index) => {
          const isLast = index === breadcrumbs.length - 1;
          return (
            <BreadcrumbItem>
              {isLast ? (
                bc.label
              ) : (
                <InternalPageLink slug={bc.link}>
                  {bc.label}&nbsp;{!isLast && '/'}
                </InternalPageLink>
              )}
            </BreadcrumbItem>
          );
        })}
      </Wrapper>
    </Content>
  );
};
