import React from 'react';
import {AnchorLink} from './AnchorLink';
import {Language} from '../../locales/types';
import download_ios_es from 'images/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg';
import download_ios_en from 'images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import download_ios_fr from 'images/Download_on_the_App_Store_Badge_FR_RGB_blk_100517.svg';
import download_ios_it from 'images/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg';
import download_ios_de from 'images/Download_on_the_App_Store_Badge_DE_RGB_blk_092917.svg';
import {useI18next} from 'gatsby-plugin-react-i18next';

const getLocalizedIOsLogo = (language: Language) => {
  switch (language) {
    case 'es':
      return download_ios_es;
    case 'en':
      return download_ios_en;
    case 'fr':
      return download_ios_fr;
    case 'it':
      return download_ios_it;
    case 'de':
      return download_ios_de;
    case 'ca':
    case 'pt':
      return download_ios_en;
  }
};

export const DownloadOnAppleStore: React.FC = () => {
  const {language} = useI18next();
  return (
    <AnchorLink target="_blank" href="https://apps.apple.com/es/app/monei-pay/id1624647668">
      <img
        src={getLocalizedIOsLogo(language as Language)}
        alt="Members of PCI Security Standards Council"
        height={50}
      />
    </AnchorLink>
  );
};

export const IosDownloadLink: React.FC = ({children}) => {
  return (
    <AnchorLink target="_blank" href="https://apps.apple.com/es/app/monei-pay/id1624647668">
      {children}
    </AnchorLink>
  );
};
