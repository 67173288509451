import React from 'react';
import {Button, ButtonProps} from 'components/Button';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {contactSalesClickEvent} from 'utils/gtmEvents';

export const ContactSalesButton: React.FC<ButtonProps> = (props) => {
  const {navigate} = useI18next();
  return (
    <Button
      {...props}
      onClick={() => {
        contactSalesClickEvent();
        navigate('/contact-sales');
      }}>
      {props.children || <Trans>Contact Sales</Trans>}
    </Button>
  );
};
