import React from 'react';
import {MenuItemIcon} from '../MenuItemIcon';
import {Item} from '../Styled';
import {InternalPageLink} from 'components/links/Pages';

export const EWallets: React.FC<{onClick: VoidFunction}> = ({onClick}) => (
  <>
    <Item>
      <MenuItemIcon name="paypal" />
      <InternalPageLink slug="payment-methods/paypal" activeClassName="active" onClick={onClick}>
        PayPal
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="click2pay" />
      <InternalPageLink slug="payment-methods/click-to-pay" activeClassName="active" onClick={onClick}>
        Click to Pay
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="google-pay" />
      <InternalPageLink slug="payment-methods/google-pay" activeClassName="active" onClick={onClick}>
        Google Pay
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="apple-pay" />
      <InternalPageLink slug="payment-methods/apple-pay" activeClassName="active" onClick={onClick}>
        Apple Pay
      </InternalPageLink>
    </Item>
  </>
);
