import {colors} from 'global.config';
import bg from 'images/bg.svg';
import {darken} from 'polished';
import {createGlobalStyle} from 'styled-components';

export const GlobalStyle = createGlobalStyle<{theme: {borderRadius: string}}>`
  html,
  body {
    height: 100%;
  }

  body {
    color: rgba(0, 0, 0, 0.7);
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: url(${bg}) no-repeat top center;
    background-size: 100%;
  }

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  .gatsby-image-wrapper {
    margin-bottom: 1.64rem;
  }

  a {
    color: ${colors.pink};
    text-decoration: none;
    transition: 250ms color;

    :hover {
      color: ${darken(0.1)(colors.pink)};
    }
  }


  .ReactModal__Overlay {
    opacity: 0;
    background-color: rgba(82, 95, 127, 0.6);
    transition: opacity 0.2s ease-in-out;
    position: fixed;
    padding: 10px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .ReactModal__Overlay--after-open {
    opacity: 1;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content {
    position: relative;
    outline: 0;
    background: #ffffff;
    padding: 35px;
    max-width: 450px;
    margin: auto;
    transition: transform 0.2s ease-in-out;
    box-shadow: 0 7px 14px 0 rgba(60, 66, 87, 0.12), 0 3px 6px 0 rgba(0, 0, 0, 0.12);
    transform: scale(0.95);
    border-radius: ${(props) => props.theme.borderRadius};
    top: 20%;
    opacity: 0;
  }

  .ReactModal__Content--after-open {
    transform: scale(1);
    opacity: 1;
  }

  .ReactModal__Content--before-close {
    transform: scale(0.95);
    opacity: 0;
  }

  @media (max-width: 1024px) {
    .hide-on-tablet {
      display: none;
    }
  }
  @media (max-width: 768px) {
    .hide-on-mobile {
      display: none;
    }

    .ReactModal__Content {
      padding: 20px;
    }
  }

  .videoWrapper {
    position: relative;
    height: 0;
  }

  .videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  @keyframes leftToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  #launcher {
    scale: 0.75 !important;
    margin: 0;
  }

  #CookiebotWidget {
    z-index: 1000 !important;
  }

  #CookiebotWidgetUnderlay {
    z-index: 1001 !important;
  }
`;
