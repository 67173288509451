export const gtmEvents = (event: string, data?: Record<string, any>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({event, ...data});
};

export const contactSalesClickEvent = () => {
  gtmEvents('Landings.ContactSalesClick');
};

export const hubspotFormSubmitEvent = (
  formId: string,
  submissionValues: Record<string, string>
) => {
  gtmEvents('Landings.HubspotFormSubmit', {formId, ...submissionValues});
};
