import React, {useState} from 'react';
import {FeaturesList, Item} from '../Styled';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import {MenuItemIcon} from '../MenuItemIcon';
import SoonIndicator from '../menu-links/SoonIndicator';
import {MenuLink} from '../types';
import {InternalPageLink} from 'components/links/Pages';
import {links} from 'global.config';
import {AnchorLink} from 'components/AnchorLink';
import {Language, targetLanguage} from '../../../../locales/types';

type Props = {
  itemClick: VoidFunction;
  title?: string | JSX.Element;
};

const resources: MenuLink[] = [
  {
    icon: 'card',
    slug: 'pricing',
    title: <Trans>Pricing</Trans>
  },
  {
    icon: 'blog',
    to: 'blog',
    title: <Trans>Blog</Trans>
  },
  {
    icon: 'support',
    to: links.support,
    title: <Trans>Support</Trans>
  },
  {
    icon: 'globe',
    to: links.docs,
    title: <Trans>Docs</Trans>
  },
  {
    icon: 'legal',
    slug: 'payments-glossary',
    title: <Trans>Glossary</Trans>
  },
  {
    icon: 'calculator',
    slug: 'interchange-fee-calculator',
    title: <Trans>Interchange Fee Calculator</Trans>
  },
  {
    icon: 'recurring',
    slug: 'affiliate-program',
    title: <Trans>Become a MONEI Affiliate</Trans>
  }
];

export const Resources: React.FC<Props> = ({itemClick, title}) => {
  const {language} = useI18next();
  const [origin, setOrigin] = useState(links.landing);
  return (
    <>
      {title}
      <FeaturesList>
        {resources.map((item) => (
          <Item>
            <MenuItemIcon name={item.icon} />
            {item.slug ? (
              <InternalPageLink slug={item.slug} activeClassName="active" onClick={itemClick}>
                {item.title}
              </InternalPageLink>
            ) : item.to === 'blog' ? (
              <AnchorLink
                target="_self"
                href={`${origin + targetLanguage[(language as Language) || 'en']}/blog/`}>
                {item.title}
              </AnchorLink>
            ) : item.to ? (
              <AnchorLink href={item.to} target="_blank">
                <Trans>{item.title}</Trans>
              </AnchorLink>
            ) : (
              item.title
            )}

            {item.beta && (
              <SoonIndicator>
                <Trans>BETA</Trans>
              </SoonIndicator>
            )}
          </Item>
        ))}
      </FeaturesList>
    </>
  );
};
