import {StickyBanner} from 'components/StickyBanner';
import {LayoutProvider} from 'contexts/LayoutContext';
import {GlobalStyle} from 'global.styles';
import {Footer} from 'layouts/Footer';
import React from 'react';
import styled, {ThemeProvider} from 'styled-components';
import {Header} from './Header';
import {Breadcrumbs} from 'components/Breadcrumbs';

type LayoutProps = {
  path: string;
  pageContext: any;
};

const theme = {
  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  borderRadius: '3px'
};

const Wrapper = styled.div`
  overflow-x: hidden;
`;

const Layout: React.FC<LayoutProps> = ({children, pageContext, ...rest}) => {
  const isNewLayout: boolean =
    pageContext.i18n.originalPath.match(
      /affiliate|notification-preferences|inactive-survey|subscribe/
    ) || pageContext.i18n.originalPath.match(/contact-sales/);

  const flatLanding: boolean =
    pageContext.i18n.originalPath.match(/sem-esp/) ||
    pageContext.i18n.originalPath.match(/ecommerce-billing/);

  return (
    <ThemeProvider theme={theme}>
      <LayoutProvider>
        {!flatLanding && <StickyBanner />}
        <Wrapper>
          <>
            <GlobalStyle />
            {!flatLanding && <Header isNewLayout={isNewLayout} />}
            <Breadcrumbs />
            {/*This span avoids weird navigation issue that puts the footer on top of the header*/}
            <span style={{display: 'none'}}> </span>
            {children}
            {!flatLanding && <Footer hideCTA={isNewLayout} />}
          </>
        </Wrapper>
      </LayoutProvider>
    </ThemeProvider>
  );
};

export default Layout;
