import React from 'react';
import {FeaturesList, Item} from '../Styled';
import {Trans} from 'gatsby-plugin-react-i18next';
import {MenuItemIcon} from '../MenuItemIcon';
import SoonIndicator from '../menu-links/SoonIndicator';
import {MenuLink} from '../types';
import {InternalPageLink} from 'components/links/Pages';

type Props = {
  itemClick: VoidFunction;
  title?: string | JSX.Element;
};

const useCases: MenuLink[] = [
  {
    icon: 'globe',
    slug: 'online-payments',
    title: <Trans>Online payments</Trans>
  },
  {
    icon: 'in-store',
    slug: 'monei-pay/retail-POS-system',
    title: <Trans>Retail & In-Store Payments</Trans>
  },
  {
    icon: 'mobile',
    slug: 'monei-pay',
    title: <Trans>Mobile Payments</Trans>
  },
  {
    icon: 'omnichannel',
    slug: 'omnichannel-payments-platform',
    title: <Trans>Omnichannel Payments</Trans>
  },
  {
    icon: 'restaurant',
    slug: 'monei-pay/qr-code-payment-restaurant',
    title: <Trans>Restaurant Payments</Trans>
  },
  {
    icon: 'scissors',
    slug: 'monei-pay/pos-systems-for-hair-salon',
    title: <Trans>Hair Salon Payments</Trans>
  },
  {
    icon: 'taxi',
    slug: 'monei-pay/pos-systems-for-taxis',
    title: <Trans>Taxi Payments</Trans>
  },
  {
    icon: 'freelancer',
    slug: 'monei-pay/pos-for-freelancers',
    title: <Trans>Freelancer Payments</Trans>
  },
  {
    icon: 'food-truck',
    slug: 'monei-pay/food-truck-pos-system',
    title: <Trans>Food Truck Payments</Trans>
  },
  {
    icon: 'dentist',
    slug: 'monei-pay/accept-payments-dental-practice',
    title: <Trans>Dental Practice Payments</Trans>
  },
  {
    icon: 'legal',
    slug: 'monei-pay/legal-billing-software',
    title: <Trans>Legal Billing Software</Trans>
  }
];

export const UseCases: React.FC<Props> = ({itemClick, title}) => {
  return (
    <>
      {title}
      <FeaturesList>
        {useCases.map((item) => (
          <Item>
            <MenuItemIcon name={item.icon} />
            {item.slug ? (
              <InternalPageLink slug={item.slug} activeClassName="active" onClick={itemClick}>
                {item.title}
              </InternalPageLink>
            ) : (
              item.title
            )}
            {item.beta && (
              <SoonIndicator>
                <Trans>BETA</Trans>
              </SoonIndicator>
            )}
          </Item>
        ))}
      </FeaturesList>
    </>
  );
};
