import React from 'react';
import {MenuItemIcon} from '../MenuItemIcon';
import {Item} from '../Styled';
import {InternalPageLink} from 'components/links/Pages';

export const PeerToPeer: React.FC<{onClick: VoidFunction}> = ({onClick}) => (
  <>
    <Item>
      <MenuItemIcon name="bizum" />
      <InternalPageLink slug="payment-methods/bizum" activeClassName="active" onClick={onClick}>
        Bizum
      </InternalPageLink>
    </Item>
    <Item>
      <MenuItemIcon name="sepa" />
      <InternalPageLink
        slug="payment-methods/sepa-request-to-pay"
        activeClassName="active"
        onClick={onClick}>
        SEPA
        <br />
        Request-to-Pay
      </InternalPageLink>
    </Item>
  </>
);
